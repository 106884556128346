/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greetingBox: {
      padding: `1rem 0`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 15%, ${theme.palette.background.default} 45%, ${theme.palette.background.default} 100%)`,
    },
    greetingPaper: {
      margin: `0 auto`,
      width: `90%`,
      maxWidth: `1200px`,
      padding: `calc(1rem + 1vw)`,
    },
    paraPosition: {
      margin: `1rem auto`,
    },
    imageBox: {
      textAlign: `right`,
    },
    imageSizeAdjust: {
      width: `30%`,
      minWidth: 180,
      maxWidth: 270,
    },
  }),
);

const GreetingCard: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.greetingBox}>
      <Paper elevation={4} className={classes.greetingPaper}>
        <Typography className={classes.paraPosition}>
          　私たちは、富士見市鶴瀬で50年以上税理士として、多くの市⺠の皆さま、富士見市で活動する法人の皆さまのお手伝いをさせていただいてきました。今までもこれからも、地域に寄り添い、親しみやすい相談しやすい事務所として、活動を続けていきます。
        </Typography>
        <Typography className={classes.paraPosition}>
          　税務のお話はもちろん、どんな些細なことでもご相談ください。私たちはいつでもお待ちしております。
        </Typography>
        <div className={classes.imageBox}>
          <StaticImage
            src="../../../images/Sign.png"
            alt="sign"
            className={classes.imageSizeAdjust}
          />
        </div>
      </Paper>
    </section>
  );
};

export default GreetingCard;
