/* eslint-disable no-irregular-whitespace */
import React from 'react';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  charterBox: {
    padding: `1rem 0`,
  },
  charterPaper: {
    margin: `0 auto`,
    width: `90%`,
    maxWidth: `1200px`,
    padding: `calc(1rem + 1vw)`,
  },
  paraPosition: {
    margin: `1rem auto`,
  },
});

const CharterCard: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.charterBox}>
      <Paper elevation={4} className={classes.charterPaper}>
        <Typography variant="h3" component="h2">
          事務所憲章
        </Typography>
        <Typography className={classes.paraPosition}>
          　事務所は富士見市で創業して50年を経ました。これからも市民を含めた関与先の皆さまに信頼される事務所を目指して事務所憲章を刻みます。
        </Typography>
        <Typography className={classes.paraPosition}>　私たちは、</Typography>
        <Typography className={classes.paraPosition}>
          　　市民や関与先に税金の仕組みをわかりやすく説明します。
        </Typography>
        <Typography className={classes.paraPosition}>
          　　関与先と納税を含めた資金繰りを一緒に考えます。
        </Typography>
        <Typography className={classes.paraPosition}>
          　　市民や関与先と税金の改正内容や支援金の情報を共有します。
        </Typography>
      </Paper>
    </section>
  );
};

export default CharterCard;
