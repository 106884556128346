import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import GreetingCard from '@/components/greeting/section/GreetingCard';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';
import CharterCard from '@/components/greeting/section/CharterCard';

const Greeting: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="所長からの挨拶"
      descriptionData="所長の鈴木弘基より皆さまにご挨拶申し上げます。"
      OgImageData="Greeting"
    />
    <FirstView pageTitle="所長からの挨拶" fileName="Greeting" />
    <GreetingCard />
    <CharterCard />
    <BottomMenu />
  </Layout>
);

export default Greeting;
